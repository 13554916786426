import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { REPORTS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  isLoading: false,
  response_additional: [],
})

export const getters = {
  getField,
}

export const actions = {
  getAdditionalPackage({ commit }, params) {
    commit('UPDATE_LOADING', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/billing/additional-balance`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_DATA', res.data)
            commit('UPDATE_LOADING', false)
            resolve(1)
          },
          (err) => {
            resolve(0)
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_LOADING(state, payload) {
    state.isLoading = payload
  },
  UPDATE_DATA(state, payload) {
    state.response_additional = payload
  },
}
