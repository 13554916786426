import requests from '../requests'
import {
  BILLINGS_URL,
  SYSTEM_SETTINGS,
  ORGANIZATION_SETTINGS_URL,
} from '@/assets/variables/endpoints'

export const state = () => ({
  feature: { web: { menu: {}, page: {} } },
  billing_info: {
    billing_enabled: '',
    package_name: '',
    status: '',
    valid_until: '',
    payment_type: '',
  },
  status: false,
  isInitiated: false,
  isSuccessCallbackSSO: {
    status: false,
    message: '',
  },
  feature_flag_state: {
    self_topup: [],
    company_validation: [],
  },
  authToSend: '',
  time_to_refresh_token_sso: null,
  chatbot_subscription: {
    list: [],
    airene_resource_enabled: false,
  },
  userManagement: {},
})

export const getters = {
  getFeatures(state) {
    return state.feature
  },
  getFeatureFlag(state) {
    return state.feature_flag_state
  },
  isInitiated(state) {
    return state.isInitiated
  },
  getIsSuccessCallbackSSO(state) {
    return state.isSuccessCallbackSSO
  },
  getBillingInfo(state) {
    return state.billing_info
  },
}

export const actions = {
  getBillingInfoConfig({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/info`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_BILLING_INFO', res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getFeatureFlag({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${SYSTEM_SETTINGS}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_FEATURE_FLAG_MENU', res.data[params])
            resolve(res.data[params])
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getPicValidation({ commit }, params) {
    return new Promise((resolve) => {
      const config = {
        headers: {
          Authorization: this.$auth.getToken('hub'),
        },
      }
      this.$axios
        .get(`${ORGANIZATION_SETTINGS_URL}/validations`, config)
        .then(({ data: res }) => {
          resolve(res.data)
        })
    })
  },
  updatePicValidation({ commit }, params) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          Authorization: this.$auth.getToken('hub'),
        },
      }
      this.$axios
        .post(`${ORGANIZATION_SETTINGS_URL}/validations`, params, config)
        .then(({ data: res }) => {
          resolve(res.data)
        })
        .catch(function (error) {
          if (error.response) {
            reject(error.response.data)
          }
        })
    })
  },
}

export const mutations = {
  FEATURE(state, settings) {
    state.feature = settings
    state.status = true
  },
  UPDATE_FEATURE_FROM_SETTINGS(state, payload) {
    payload.menu.forEach((data) => {
      state.feature.menu = {
        ...state.feature.menu,
        ...data,
      }
    })
    payload.submenu.forEach((data) => {
      state.feature.submenu = {
        ...state.feature.submenu,
        ...data,
      }
    })
  },
  UPDATE_FIRST_INIT(state, payload) {
    state.isInitiated = payload
  },
  SET_SUCCESS_CALLBACK_SSO(state, payload) {
    state.isSuccessCallbackSSO = payload
  },
  SET_FEATURE_FLAG_MENU(state, payload) {
    if (state.feature_flag_state) {
      state.feature_flag_state[payload.key] = payload
    }
  },
  SET_BILLING_INFO(state, payload) {
    state.billing_info = payload
  },
  SET_NEW_TOKEN(state, payload) {
    state.authToSend = payload
  },
  SET_SSO_REFRESH_TIME(state, payload) {
    state.time_to_refresh_token_sso = payload
    localStorage.setItem('time_to_refresh', payload)
  },
  SET_CHATBOT_SUBSCRIPTION(state, payload) {
    state.chatbot_subscription = payload
  },
  UPDATE_NEW_USER_MANAGEMENT(state, payload) {
    state.userManagement = payload
  },
}
